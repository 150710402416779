// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from '#packages/constants';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  type: AnyFixMe;
  close: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'notificationFrame';

  static propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    type: PropTypes.oneOf(_.values(constants.NOTIFICATIONS.TYPES)).isRequired,
    close: PropTypes.func.isRequired,
  };

  onCloseClicked = () => {
    this.props.close();
  };

  getGutterClass = () => {
    return `gutter-${this.props.type}`;
  };

  render() {
    return (
      <div>
        <div
          key="notification-frame-editor"
          data-aid="notificationFrameIndication"
          className="notification-frame-editor oneLineNotification"
        >
          <div className="notification-frame-wrapper">
            <div
              onClick={this.onCloseClicked}
              data-aid="notification-frame-close"
              className="close-notification"
            >
              <a>
                <symbols.symbol name="bubble-close" />
              </a>
            </div>
            <div className={`notification-gutter ${this.getGutterClass()}`} />
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
