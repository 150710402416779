// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import * as UA from '#packages/uiAnimations';
import LazyComponent from '#packages/lazyComponent';

//TYPE WAS GENERATED, remove this line when reviewed
interface NotificationCenterProps {
  notifications: Array<AnyFixMe>;
  closeNotificationById: FunctionFixMe;
  closeAllNotifications: FunctionFixMe;
}

class NotificationCenter extends React.Component<NotificationCenterProps> {
  static displayName = 'notificationCenter';

  static propTypes = {
    notifications: PropTypes.array.isRequired,
    closeNotificationById: PropTypes.func.isRequired,
    closeAllNotifications: PropTypes.func.isRequired,
  };

  getNotificationProps = (notification) => {
    //todo verify issue with id field
    return _.omit(notification, ['name']);
  };

  closeAll = () => {
    _.invokeMap(this.props.notifications, 'onClose');
    this.props.closeAllNotifications();
  };

  render() {
    return (
      <div>
        <div
          key="editor-notifications"
          data-aid="notificationCenterIndication"
          className="notification-center-editor"
        >
          <UA.listAddRemove>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.notifications, (notification) => (
              <LazyComponent
                moduleName={notification.name}
                key={notification.id}
                close={() => this.props.closeNotificationById(notification.id)}
                {...this.getNotificationProps(notification)}
              />
            ))}
          </UA.listAddRemove>
        </div>
      </div>
    );
  }
}

function getNotificationsInCorrectOrder(state) {
  return _.clone(
    stateManagement.notifications.selectors.selectNotifications(state),
  ).reverse();
}

const mapStateToProps = ({ state }) => ({
  notifications: getNotificationsInCorrectOrder(state),
});

const { closeNotification, closeAllNotifications } =
  stateManagement.notifications.actions;

const mapDispatchToProps = (dispatch) => ({
  closeNotificationById: (id) => dispatch(closeNotification(id)),
  closeAllNotifications: () => dispatch(closeAllNotifications()),
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(NotificationCenter);
