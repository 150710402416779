import React, { useState } from 'react';
import { TextLabel, RichText } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import NotificationFrame from '../notificationFrame/notificationFrame';

interface ErrorNotificationProps {
  name: string;
  message: string;
  close: () => void;
  methodName: string;
}

const getMethodName = (methodName: string) => `Method Name: ${methodName}`;
const getErrorMessage = (message: string) => `Error: ${message}`;
const editorUrl = `Editor: ${window.location.href}`;

const ErrorNotification = ({
  message,
  methodName,
  close,
}: ErrorNotificationProps) => {
  const [shouldShowCopiedIndication, setShouldShowCopiedIndication] =
    useState(false);
  const copyText = () => {
    navigator.clipboard
      .writeText(
        `${getMethodName(methodName)}: \n${getErrorMessage(
          message,
        )} \n${editorUrl}`,
      )
      .then(
        () => {
          setShouldShowCopiedIndication(true);
          setTimeout(() => {
            setShouldShowCopiedIndication(false);
          }, 2000);
        },
        (err) => {
          console.error('Could not copy text', err.message);
        },
      );
  };
  return (
    <NotificationFrame close={close} type="error">
      <div className="error-notification-content">
        <RichText className="error-title">
          <span>
            Hi Wixer, something went wrong. Help us out by copying the error and
            sending it to
          </span>
          <b> #bugs-reports-editor</b>
          <span> on slack.</span>
        </RichText>
        <TextLabel shouldTranslate={false} value={getMethodName(methodName)} />
        <br />
        <TextLabel shouldTranslate={false} value={getErrorMessage(message)} />
        <div className="error-notification-copy-paste" onClick={copyText}>
          {shouldShowCopiedIndication ? (
            <div className="action-success-symbol">
              <symbols.symbol name="topBarActionSuccess" />
              <div className="symbol-mask" />
              <div className="circle" />
            </div>
          ) : (
            <RichText>
              <a className="error-notification-copy-paste-text">Copy Error</a>
            </RichText>
          )}
        </div>
      </div>
    </NotificationFrame>
  );
};

export default ErrorNotification;
