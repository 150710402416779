// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';

const {
  closeNotification,
  closeAllNotifications,
  addNotification,
  clearNotificationTimer,
  resetNotificationTimer,
} = stateManagement.notifications.actions;

export default {
  mapStateToProps: ({ state }) => ({
    notifications: _.clone(
      stateManagement.notifications.selectors.selectNotifications(state),
    ),
  }),
  mapDispatchToProps: (dispatch) => ({
    closeNotificationById: (id) => dispatch(closeNotification(id)),
    closeAllNotifications: () => dispatch(closeAllNotifications()),
    addNotification: () => dispatch(addNotification()),
    clearNotificationTimer: (notification, notificationState) =>
      dispatch(clearNotificationTimer(notification, notificationState)),
    resetNotificationTimer: (notification, notificationState, index) =>
      dispatch(resetNotificationTimer(notification, notificationState, index)),
    biEvent: (event, parameters) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
  }),
};
